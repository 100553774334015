<script>
import { required, email, helpers } from "@vuelidate/validators";
import appConfig from "../../../app.config";
import Axios from "@/config/axios";

export default {
  page: {
    title: "Login",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      password_type: "password",
    };
  },
  validations: {
    email: {
      required: helpers.withMessage("Email is required", required),
      email: helpers.withMessage("Please enter valid email", email),
    },
    password: {
      required: helpers.withMessage("Password is required", required),
    },
  },
  computed: {},
  methods: {
    togglePassword() {
      this.password_type =
        this.password_type == "password" ? "text" : "password";
    },
    async signinapi() {
      const result = await Axios.post("/auth/login", {
        email: this.email,
        password: this.password,
        location: { ip: "127.0.0.2", country_code: "cm" },
      })
        .then((res) => res)
        .catch((e) => {
          return e.response;
        });
      if (result?.data?.error == true) {
        return (this.authError = result.data.message);
      }
      localStorage.setItem("jwt", result.data.token);
      this.$router.push({
        path: "/products",
      });
    },
  },
};
</script>

<template>
  <div class="auth-page-wrapper pt-5">
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-overlay"></div>

      <div class="shape">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1440 120"
        >
          <path
            d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"
          ></path>
        </svg>
      </div>
      <Particles
        id="tsparticles"
        :options="{
          particles: {
            number: {
              value: 90,
              density: {
                enable: true,
                value_area: 800,
              },
            },
            color: {
              value: '#ffffff',
            },
            shape: {
              type: 'circle',
              stroke: {
                width: 0,
                color: '#000000',
              },
              polygon: {
                nb_sides: 5,
              },
              image: {
                src: 'img/github.svg',
                width: 100,
                height: 100,
              },
            },
            opacity: {
              value: 0.8,
              random: true,
              anim: {
                enable: true,
                speed: 1,
                opacity_min: 0,
                sync: false,
              },
            },
            size: {
              value: 4,
              random: true,
              anim: {
                enable: false,
                speed: 4,
                size_min: 0.2,
                sync: false,
              },
            },
            line_linked: {
              enable: false,
              distance: 150,
              color: '#ffffff',
              opacity: 0.4,
              width: 1,
            },
            move: {
              enable: true,
              speed: 2,
              direction: 'none',
              random: false,
              straight: false,
              out_mode: 'out',
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200,
              },
            },
          },
          interactivity: {
            detect_on: 'canvas',
            events: {
              onhover: {
                enable: true,
                mode: 'bubble',
              },
              onclick: {
                enable: true,
                mode: 'repulse',
              },
              resize: true,
            },
            modes: {
              grab: {
                distance: 400,
                line_linked: {
                  opacity: 1,
                },
              },
              bubble: {
                distance: 400,
                size: 4,
                duration: 2,
                opacity: 0.8,
                speed: 3,
              },
              repulse: {
                distance: 200,
              },
              push: {
                particles_nb: 4,
              },
              remove: {
                particles_nb: 2,
              },
            },
          },
          retina_detect: true,
          config_demo: {
            hide_card: false,
            background_color: '#b61924',
            background_image: '',
            background_position: '50% 50%',
            background_repeat: 'no-repeat',
            background_size: 'cover',
          },
        }"
      />
    </div>

    <!-- auth page content -->
    <div class="auth-page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mt-sm-5 mb-4 text-white-50">
              <div>
                <router-link to="/" class="d-inline-block auth-logo">
                  <img
                    src="@/assets/images/logo-light.png"
                    alt=""
                    height="20"
                  />
                </router-link>
              </div>
              <p class="mt-3 fs-15 fw-medium">TANOU Academy Store Dashboard</p>
            </div>
          </div>
        </div>
        <!-- end row -->

        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card mt-4">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">Bienvenue !</h5>
                  <p class="text-muted">Connectez-vous pour y accéder.</p>
                </div>
                <div class="p-2 mt-4">
                  <b-alert
                    v-model="authError"
                    variant="danger"
                    class="mt-3"
                    dismissible
                    >{{ authError }}</b-alert
                  >

                  <div></div>

                  <form @submit.prevent="tryToLogIn">
                    <div class="mb-3">
                      <label for="email" class="form-label"
                        >Adresse E-mail</label
                      >
                      <input
                        type="email"
                        class="form-control"
                        id="email"
                        placeholder="Votre adresse e-mail"
                        v-model="email"
                      />
                      <div class="invalid-feedback">
                        <span></span>
                      </div>
                    </div>

                    <div class="mb-3">
                      <!-- <div class="float-end">
                        <router-link to="/forgot-password" class="text-muted"
                          >Forgot password?</router-link
                        >
                      </div> -->
                      <label class="form-label" for="password-input">
                        Mot de passe
                      </label>
                      <div class="position-relative auth-pass-inputgroup mb-3">
                        <input
                          :type="password_type"
                          v-model="password"
                          class="form-control pe-5"
                          placeholder="Votre mot de passe"
                          id="password-input"
                        />
                        <button
                          class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                          type="button"
                          id="password-addon"
                          @click="togglePassword"
                        >
                          <i class="ri-eye-fill align-middle"></i>
                        </button>
                        <div class="invalid-feedback">
                          <span></span>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                      <label class="form-check-label" for="auth-remember-check">Remember
                        me</label>
                    </div> -->

                    <div class="mt-4">
                      <button
                        class="btn btn-success w-100"
                        type="submit"
                        @click="signinapi"
                      >
                        Se connecter
                      </button>
                    </div>

                    <!-- <div class="mt-4 text-center">
                      <div class="signin-other-title">
                        <h5 class="fs-13 mb-4 title">Sign In with</h5>
                      </div>
                      <div>
                        <button type="button" class="btn btn-primary btn-icon
                          waves-effect waves-light">
                          <i class="ri-facebook-fill fs-16"></i>
                        </button>
                        <button type="button" class="btn btn-danger btn-icon
                          waves-effect waves-light
                          ms-1">
                          <i class="ri-google-fill fs-16"></i>
                        </button>
                        <button type="button" class="btn btn-dark btn-icon
                          waves-effect waves-light
                          ms-1">
                          <i class="ri-github-fill fs-16"></i>
                        </button>
                        <button type="button" class="btn btn-info btn-icon
                          waves-effect waves-light
                          ms-1">
                          <i class="ri-twitter-fill fs-16"></i>
                        </button>
                      </div>
                    </div> -->
                  </form>
                </div>
              </div>
              <!-- end card body -->
            </div>
            <!-- end card -->

            <!-- <div class="mt-4 text-center">
              <p class="mb-0">
                Don't have an account ?
                <router-link to="/register" class="fw-semibold text-primary
                  text-decoration-underline">
                  Signup
                </router-link>
              </p>
            </div> -->
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0 text-muted">
                &copy; {{ new Date().getFullYear() }} KoueLab E-commerce Admin.
                Crafted with <i class="mdi mdi-heart text-danger"></i> by
                KoueLab
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->
  </div>
  <!-- end auth-page-wrapper -->
</template>
